<template>
  <div class="skill animate__animated animate__fadeIn animate__slow">
    <section class="hero is-fullheight">
      <!-- Hero head: will stick at the top -->
      <div class="hero-head">
        <div class="container is-max-widescreen">
          <Navbar />
        </div>
      </div>

      <!-- Hero content: will be in the middle -->
      <div class="hero-body">
        <div class="container is-max-widescreen">
          <div class="columns">
            <div class="column is-three-quarters">
              <div class="content is-large">
                <h1 class="has-text-black is-size-3-mobile">
                  Here are few technologies I've been working with though my career
                </h1>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="tile is-ancestor">
                <div class="tile is-parent">
                  <article class="tile">
                    <div class="content">
                      <p class="subtitle has-text-weight-bold has-text-black">Design</p>
                      <!-- <p class="subtitle">With even more content</p> -->
                      <hr class="navbar-divider" />
                      <div class="content">
                        <!-- Content -->
                        <ul class="skill">
                          <li>Wireframing</li>
                          <li>Rapid Prototyping</li>
                          <li>Storyboarding</li>
                          <li>User Journey Mapping</li>
                          <li>Information Architecture</li>
                          <li>User Interface Design</li>
                          <li>Visual Design</li>
                        </ul>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="tile is-parent">
                  <article class="tile">
                    <div class="content">
                      <p class="subtitle has-text-weight-bold has-text-black">Coding</p>
                      <!-- <p class="subtitle">With even more content</p> -->
                      <hr class="navbar-divider" />
                      <div class="content">
                        <!-- Content -->
                        <ul class="skill">
                          <li>Vue.js</li>
                          <li>HTML/CSS/JS</li>
                          <li>Nuxt</li>
                          <li>Ionic</li>
                          <li>Bootstrap</li>
                          <li>WordPress</li>
                        </ul>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="tile is-parent">
                  <article class="tile">
                    <div class="content">
                      <p class="subtitle has-text-weight-bold has-text-black">Tools</p>
                      <!-- <p class="subtitle">With even more content</p> -->
                      <hr class="navbar-divider" />
                      <div class="content">
                        <!-- Content -->
                        <ul class="skill">
                          <li>Figma</li>
                          <li>Sketch</li>
                          <li>Adobe XD</li>
                          <li>inVision</li>
                          <li>Axures</li>
                          <li>MockingBot</li>
                          <li>Mira</li>
                          <li>Illustrator</li>
                        </ul>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="tile is-parent">
                  <article class="tile">
                    <div class="content">
                      <p class="subtitle has-text-weight-bold has-text-black">Others</p>
                      <!-- <p class="subtitle">With even more content</p> -->
                      <hr class="navbar-divider" />
                      <div class="content">
                        <!-- Content -->
                        <ul class="skill">
                          <li>Team oriented</li>
                          <li>Self-motivated</li>
                          <li>Time management</li>
                          <li>Effective communicator</li>
                          <li>Attention to detail</li>
                        </ul>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
          <!-- <a rel="ar" href="img/demo.usdz">ar</a> -->
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "skill",
  props: {
    msg: String,
  },
  components: {
    Navbar, Footer
  },
  mounted() {
    this.track();
  },
  methods: {
    track() {
      this.$gtag.pageview("/skill");
      this.$gtag.query('event', 'screen_view',{
        app_name: 'juliandesign.io',
        screen_name: 'Skill',
      })
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul.skill {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul.skill li {
  margin: 1.5rem 0;
}
</style>
